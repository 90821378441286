import { Button, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Customer, User } from '../../../../api';
import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import UserForm from '../detail';
import { ExportPdfProps } from '../../../../helpers/pdf';

const label = 'cliente';
const route = '/customers';
const titlePdf = 'Reporte de clientes';

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<User>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const CustomersList = () => {
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<User> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Nombre',
        width: 150,
        dataIndex: 'username',
        key: 'username',
        ...getColumnSearchProps('username'),
        render: (username) => (
          <>
            {username && (
              <Text>
                {username.charAt(0).toUpperCase() + username.slice(1)}
              </Text>
            )}
            {!username && (
              <Text disabled italic>
                No asignado
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Empresa',
        width: 150,
        dataIndex: 'company_name',
        key: 'company_name',
        ...getColumnSearchProps('company_name'),
        render: (company_name) => (
          <Text>
            {company_name.charAt(0).toUpperCase() + company_name.slice(1)}
          </Text>
        ),
      },
      {
        title: 'Dirección',
        width: 150,
        dataIndex: 'address_1',
        key: 'address_1',
        ...getColumnSearchProps('address_1'),
        render: (address_1) => (
          <Text>{address_1.charAt(0).toUpperCase() + address_1.slice(1)}</Text>
        ),
      },
      {
        title: 'Teléfono',
        width: 150,
        dataIndex: 'phone',
        key: 'phone',
        ...getColumnSearchProps('phone'),
      },
      {
        title: 'Ciudad',
        width: 150,
        dataIndex: 'city',
        key: 'city',
        ...getColumnSearchProps('city'),
        render: (city) => (
          <Text>{city.charAt(0).toUpperCase() + city.slice(1)}</Text>
        ),
      },
      {
        title: 'Estado',
        width: 150,
        dataIndex: 'state',
        key: 'state',
        ...getColumnSearchProps('state'),
        render: (state) => (
          <Text>{state.charAt(0).toUpperCase() + state.slice(1)}</Text>
        ),
      },
      {
        title: 'Correo electrónico',
        width: 100,
        dataIndex: 'email',
        key: 'email',
        ...getColumnSearchProps('email'),
        render: (email) => <Text>{email.toLowerCase()}</Text>,
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 150,
        align: 'center',
        render: (user) => (
          <>
            <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="default"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', user.id)}
              />
            </Tooltip>
            <Tooltip title="Editar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#1890ff',
                  borderColor: '#1890ff',
                }}
                type="primary"
                shape="default"
                size="small"
                icon={<EditOutlined />}
                onClick={() => showModal('edit', user.id)}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#e63241',
                  borderColor: '#cf1322',
                }}
                type="primary"
                shape="default"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(user.id)}
              />
            </Tooltip>
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Clientes',
    sheetName: 'Clientes',
    tableName: 'Tabla de clientes',
    columnNames: [
      { label: 'Nombre', key: 'username' },
      { label: 'Empresa', key: 'company_name' },
      { label: 'Dirección', key: 'address_1' },
      { label: 'Teléfono', key: 'phone' },
      { label: 'Ciudad', key: 'city' },
      { label: 'Estado', key: 'state' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'Nombre', key: 'username', width: 50 },
    { header: 'Empresa', key: 'company_name', width: 30 },
    { header: 'Dirección', key: 'address_1' },
    { header: 'Teléfono', key: 'phone' },
    { header: 'Ciudad', key: 'city' },
    { header: 'Estado', key: 'state' },
  ];

  const userTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: UserForm,
    pdfColumns,
    titlePdf,
  };
  const UsersTable = Table<Customer>(userTableProps);

  return UsersTable;
};

export default CustomersList;
