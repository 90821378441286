import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  FileProtectOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import './menu-sider.scss';
import CanShow from '../../can-show';

const MenuSider = ({
  menuCollapsed,
  roles = [],
}: {
  menuCollapsed: boolean | undefined;
  roles: string[];
}) => {
  const { Sider } = Layout;
  const location = useLocation();

  return (
    <Sider
      className="admin-sider"
      collapsed={menuCollapsed}
      collapsible
      breakpoint="md"
      collapsedWidth="0"
      width={220}
      style={{ position: 'fixed' }}
    >
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/">
          <Link to="/">
            <HomeOutlined />
            <span className="nav-text">Inicio</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/contracts">
          <Link to="/contracts">
            <FileProtectOutlined />
            <span className="nav-text">Contratos</span>
          </Link>
        </Menu.Item>
        <CanShow roles={['Customer']}>
          <Menu.Item key="/suppliers" style={{ marginLeft: 28 }}>
            <Link to="/suppliers">
              <UserOutlined />
              <span className="nav-text">Proveedores</span>
            </Link>
          </Menu.Item>
        </CanShow>
        <Menu.Item key="/administration">
          <CanShow roles={['Admin', 'Financial Advisor']}>
            <Link to="/administration">
              <ToolOutlined />
              <span className="nav-text">Administración</span>
            </Link>
          </CanShow>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default MenuSider;
