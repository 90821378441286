import { Button, Col, Divider, Row, Typography } from 'antd';
import React, { ReactElement, useState } from 'react';
import {
  PlusCircleOutlined,
  EyeOutlined,
  EditOutlined,
} from '@ant-design/icons';
import SupplierImg from '../../../assets/img/png/supplier.png';
import UsersImg from '../../../assets/img/png/users.png';
import CustomerImg from '../../../assets/img/png/customer.png';
import DocumentsImg from '../../../assets/img/png/documents.png';
import RepseImg from '../../../assets/img/png/repse.png';
import AddDocumentsImg from '../../../assets/img/png/add-document.png';
import Modal from '../../general/modal/index';
import { useNavigate } from 'react-router-dom';
import CanShow from '../../can-show';
import UserForm from '../../../pages/administration/users/detail';
import SupplierForm from '../../../pages/administration/suppliers/detail';
import CustomerForm from '../../../pages/administration/customers/detail';
import { Supplier, post, put } from '../../../api';
import ContractsForm from '../../../pages/home/contracts/detail';
import RepseForm from '../../../pages/home/repse';
import { useUser } from '../../../hooks';

const CardsHome = () => {
  const { Title } = Typography;
  let navigate = useNavigate();
  const { user } = useUser();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [contextModal, setContextModal] = useState<ReactElement | null>(null);
  const showModal = (type: string, typeForm?: string) => {
    setTitleModal(`Agregar ${type}`);
    setModalIsVisible(true);
    if (type === 'usuario') {
      setContextModal(() => (
        <UserForm
          type={'add'}
          add={(usr) =>
            post('/users', usr, true).then((e) => {
              if (e.statusCode === 200) {
                setModalIsVisible(false);
              }
            })
          }
          update={(usr) =>
            put('/users', usr, true).then((e) => {
              if (e.statusCode === 200) {
                setModalIsVisible(false);
              }
            })
          }
          // setModalIsVisible={setModalIsVisible}
        />
      ));
    } else if (type === 'proveedor') {
      setContextModal(
        <SupplierForm
          type={'add'}
          add={(sup) =>
            post('/supliers', sup, true).then((e) => {
              if (e.statusCode === 200) {
                setModalIsVisible(false);
              }
            })
          }
          update={(sup) =>
            put('/supliers', sup, true).then((e) => {
              if (e.statusCode === 200) {
                setModalIsVisible(false);
              }
            })
          }
          // setModalIsVisible={setModalIsVisible}
        />
      );
    } else if (type === 'cliente') {
      setContextModal(
        <CustomerForm
          type={'add'}
          add={(sup) =>
            post('/customers', sup, true).then((e) => {
              if (e.statusCode === 200) {
                setModalIsVisible(false);
              }
            })
          }
          update={(sup) =>
            put('/customers', sup, true).then((e) => {
              if (e.statusCode === 200) {
                setModalIsVisible(false);
              }
            })
          }
          // setModalIsVisible={setModalIsVisible}
        />
      );
    } else if (type === 'contrato') {
      setContextModal(
        <ContractsForm
          type={'add'}
          add={(contract) =>
            post('/contracts', contract, true).then((e) => {
              if (e.statusCode === 200) {
                setModalIsVisible(false);
              }
            })
          }
          update={(contract) =>
            put('/contracts', contract, true).then((e) => {
              if (e.statusCode === 200) {
                setModalIsVisible(false);
              }
            })
          }
          // setModalIsVisible={setModalIsVisible}
        />
      );
    } else if (type === 'REPSE' && typeForm) {
      setTitleModal(`${typeForm === 'add' ? 'Agrerar' : 'Editar'} ${type}`);
      setContextModal(
        <RepseForm
          type={typeForm}
          supplierId={user ? (user.extendedInfo as Supplier).id : 0}
          add={(repse: any) => setModalIsVisible(false)}
          update={(repse: any) => {
            setModalIsVisible(false);
            setContextModal(null);
          }}
        />
      );
    }
  };

  return (
    <div>
      <Row gutter={16}>
        <CanShow roles={['Supplier']}>
          <Col lg={8} md={12} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} alt="" src={RepseImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  REPSE
                </Title>
                <Divider />
              </Col>
              <Col style={{ justifyContent: 'center', display: 'flex' }}>
                {user &&
                  user.role_id === 3 &&
                  (user.extendedInfo as Supplier).repse_id === null && (
                    <Button
                      onClick={() => showModal('REPSE', 'add')}
                      className="btn-download"
                      icon={<PlusCircleOutlined />}
                      style={{ width: 120 }}
                    >
                      Agregar
                    </Button>
                  )}
                {user &&
                  user.role_id === 3 &&
                  (user.extendedInfo as Supplier).repse_id !== null && (
                    <Button
                      onClick={() => showModal('REPSE', 'edit')}
                      className="btn-download"
                      icon={<EditOutlined />}
                      style={{ width: 120 }}
                    >
                      Editar
                    </Button>
                  )}
              </Col>
            </div>
          </Col>
        </CanShow>
        <Col lg={8} md={12} xs={24}>
          <div className="admin__container">
            <Col>
              <img width={'100%'} alt="" src={DocumentsImg} />
              <Title
                style={{
                  color: 'black',
                  marginTop: 10,
                  textAlign: 'center',
                }}
                level={5}
              >
                Ver contratos
              </Title>
              <Divider />
            </Col>
            <Col style={{ justifyContent: 'center', display: 'flex' }}>
              <Button
                onClick={() => navigate(`/contracts`)}
                className="btn-download"
                icon={<EyeOutlined />}
                style={{ width: 120 }}
              >
                Visualizar
              </Button>
            </Col>
          </div>
        </Col>
        <CanShow roles={['Admin', 'Financial Advisor']}>
          <Col lg={8} md={12} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} alt="" src={AddDocumentsImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Agregar contrato
                </Title>
                <Divider />
              </Col>
              <Col style={{ justifyContent: 'center', display: 'flex' }}>
                <Button
                  className="btn-download"
                  onClick={() => showModal('contrato')}
                  icon={<PlusCircleOutlined />}
                  style={{ width: 120 }}
                >
                  Agregar
                </Button>
              </Col>
            </div>
          </Col>
        </CanShow>
        <CanShow roles={['Customer']}>
          <Col lg={8} md={12} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} alt="" src={SupplierImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Proveedores
                </Title>
                <Divider />
              </Col>
              <Row justify="end" style={{ justifyContent: 'center' }}>
                <Col>
                  <Button
                    onClick={() => navigate(`/suppliers`)}
                    className="btn-download"
                    icon={<EyeOutlined />}
                    style={{ width: 120 }}
                  >
                    Visualizar
                  </Button>
                  {/* )} */}
                </Col>
              </Row>
            </div>
          </Col>
        </CanShow>
        <CanShow roles={['Admin', 'Financial Advisor']}>
          <>
            <Col lg={8} md={12} xs={24}>
              <div className="admin__container">
                <Col>
                  <img width={'100%'} alt="" src={UsersImg} />
                  <Title
                    style={{
                      color: 'black',
                      marginTop: 10,
                      textAlign: 'center',
                    }}
                    level={5}
                  >
                    Usuarios
                  </Title>
                  <Divider />
                </Col>
                <Row justify="end" style={{ justifyContent: 'center' }}>
                  <Col style={{ display: 'flex' }}>
                    <Button
                      onClick={() => showModal('usuario')}
                      className="btn-download"
                      icon={<PlusCircleOutlined />}
                      style={{ width: 120 }}
                    >
                      Agregar
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      onClick={() => navigate(`/administration/users`)}
                      className="btn-download"
                      icon={<EyeOutlined />}
                      style={{ width: 120 }}
                    >
                      Visualizar
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={8} md={12} xs={24}>
              <div className="admin__container">
                <Col>
                  <img width={'100%'} alt="" src={SupplierImg} />
                  <Title
                    style={{
                      color: 'black',
                      marginTop: 10,
                      textAlign: 'center',
                    }}
                    level={5}
                  >
                    Proveedores
                  </Title>
                  <Divider />
                </Col>
                <Row justify="end" style={{ justifyContent: 'center' }}>
                  <Col style={{ display: 'flex' }}>
                    {/* {[ROL_ADMIN].some((r) => person?.role.includes(r)) && ( */}
                    <Button
                      onClick={() => showModal('proveedor')}
                      className="btn-download"
                      icon={<PlusCircleOutlined />}
                      style={{ width: 120 }}
                    >
                      Agregar
                    </Button>
                    {/* )} */}
                  </Col>

                  <Col>
                    {/* {[ROL_SUPPLIER].some((r) => person?.role.includes(r)) && ( */}
                    <Button
                      onClick={() => navigate(`/administration/suppliers`)}
                      className="btn-download"
                      icon={<EyeOutlined />}
                      style={{ width: 120 }}
                    >
                      Visualizar
                    </Button>
                    {/* )} */}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={8} md={12} xs={24}>
              <div className="admin__container">
                <Col>
                  <img width={'100%'} alt="" src={CustomerImg} />
                  <Title
                    style={{
                      color: 'black',
                      marginTop: 10,
                      textAlign: 'center',
                    }}
                    level={5}
                  >
                    Clientes
                  </Title>
                  <Divider />
                </Col>
                <Row justify="end" style={{ justifyContent: 'center' }}>
                  <Col style={{ display: 'flex' }}>
                    <Button
                      onClick={() => showModal('cliente')}
                      className="btn-download"
                      icon={<PlusCircleOutlined />}
                      style={{ width: 120 }}
                    >
                      Agregar
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      onClick={() => navigate(`/administration/customers`)}
                      className="btn-download"
                      icon={<EyeOutlined />}
                      style={{ width: 120 }}
                    >
                      Visualizar
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </>
        </CanShow>
      </Row>
      <Modal
        title={titleModal}
        isVisible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default CardsHome;
