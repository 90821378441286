// import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

type ExportPdfProps = {
  header: string;
  key: string;
  width?: number;
};
type TableRow = {
  [key: string]: string | number;
};

const exportToPdf = (
  data: TableRow[],
  columns: ExportPdfProps[],
  title: string
) => {
  const doc = new jsPDF('landscape');

  doc.setFontSize(16);
  doc.text(title, 10, 10);

  (doc as any).autoTable({
    head: [columns.map((col) => col.header)],
    body: data.map((row) => columns.map((col) => row[col.key])),
    startY: 20,
  });
  const fileName = title.replace(' ', '_');

  doc.save(`${fileName}.pdf`);
};

export type { ExportPdfProps };
export { exportToPdf };
