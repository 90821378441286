import React from 'react';
import { Layout } from 'antd';
import GreetingsTop from '../../../components/menu/greetings-top';
import ProfileForm from '../../../components/general/profile-form';
import './profile.scss';

export default function Profile() {
  return (
    <Layout>
      <GreetingsTop />
      <div className="profile__container">
        <ProfileForm />
      </div>
    </Layout>
  );
}
