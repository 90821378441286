import { DashboardLayout } from '../layouts';
import { Home, Login, PasswordRecovery, ResetPassword } from '../pages';
import Administration from '../pages/administration';
import Suppliers from '../pages/administration/suppliers';
import SuppliersTable from '../pages/administration/suppliers/index';
import Contracts from '../pages/home/contracts';
import FileContracts from '../pages/home/files';
import Users from '../pages/administration/users';
import Profile from '../pages/home/profile';
import { CanShow } from '../components';
import UnAuthorized from '../pages/home/unauthorized';
import Customers from '../pages/administration/customers';

type Route = {
  path: string;
  element: React.ReactNode;
  roles: string[];
  children?: Route[];
};

const routes: Route[] = [
  {
    path: '/login',
    element: <Login />,
    roles: [],
  },
  {
    path: '/forgot-password',
    element: <PasswordRecovery />,
    roles: [],
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    roles: [],
  },

  {
    path: '/',
    element: <DashboardLayout />,
    roles: [],
    children: [
      {
        path: '/',
        element: <Home />,
        roles: [],
      },
      {
        path: '/administration',
        element: (
          <CanShow
            roles={['Admin', 'Financial Advisor']}
            redirect="/unauthorized"
          >
            <Administration />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/administration/suppliers',
        element: (
          <CanShow
            roles={['Admin', 'Financial Advisor']}
            redirect="/unauthorized"
          >
            <Suppliers />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/administration/users',
        element: (
          <CanShow
            roles={['Admin', 'Financial Advisor']}
            redirect="/unauthorized"
          >
            <Users />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/administration/customers',
        element: (
          <CanShow
            roles={['Admin', 'Financial Advisor']}
            redirect="/unauthorized"
          >
            <Customers />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/profile',
        element: <Profile />,
        roles: [],
      },
      {
        path: '/contracts',
        element: <Contracts />,
        roles: [],
      },
      {
        path: '/contract/files/:id',
        element: <FileContracts />,
        roles: [],
      },
      {
        path: '/suppliers',
        element: <SuppliersTable />,
        roles: [],
      },
      {
        path: '/unauthorized',
        element: <UnAuthorized />,
        roles: [],
      },
    ],
  },
];

export type { Route };
export default routes;
