import React, { useEffect, useState } from 'react';
import { useUser } from '../../hooks';
import { useNavigate } from 'react-router-dom';

type CanShowProps = {
  children: React.ReactElement;
  roles: string[];
  redirect?: string;
};

const CanShow = (props: CanShowProps) => {
  const { children, roles, redirect } = props;
  const { user, isLoading } = useUser();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (redirect !== undefined && shouldRedirect) {
      navigate(redirect);
    }
  }, [navigate, redirect, shouldRedirect]);

  const Render = React.useMemo(() => {
    if (!isLoading && user !== null) {
      if (roles.includes(user.role_description)) return children;
      else setShouldRedirect(true);
    }
    return null;
  }, [isLoading, user, roles, children]);

  return Render;
};

export default CanShow;
