import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/menu/greetings-top';

import './contracts.scss';
import ContractsList from './list';

export default function Contracts() {
  const { Title } = Typography;
  return (
    <Layout>
      <GreetingsTop />
      <div className="catalog__container">
        <Row className="header-top">
          <Col md={12} xs={24} className="header-top__left">
            <Title
              level={3}
              style={{ top: 0, bottom: 0, height: '78%', margin: 'auto' }}
            >
              Contratos
            </Title>
          </Col>
        </Row>
      </div>
      <div className="catalog__container">
        <ContractsList />
      </div>
    </Layout>
  );
}
