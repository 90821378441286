import React from 'react';

import { Layout, Row, Col, Typography } from 'antd';
import logoPlanB from '../../assets/img/png/logo_planb.png';
import logo from '../../assets/img/png/ap-logo-blue.png';
import PasswordRecoveryForm from '../../components/general/password-recovery-form';

export default function PasswordRecovery() {
  const { Content } = Layout;
  const { Paragraph } = Typography;

  return (
    <Layout className="login">
      <Content className="login__content" style={{ padding: 20 }}>
        <div className="login__content-banner animate__animated  animate__slideInDown">
          <Row className="login-container">
            <Col span={24}>
              <img src={logo} alt="logo" style={{ width: 120 }} />
              <p
                style={{
                  fontWeight: 300,
                  lineHeight: 1.4,
                  fontSize: 18,
                  textAlign: 'center',
                  marginTop: 20,
                }}
              >
                {' '}
                Enviaremos la contraseña al correo que tienes registrado
              </p>
            </Col>
          </Row>
          <PasswordRecoveryForm />
          <Row className="login-container">
            <Col>
              <Paragraph>Desarrollado por:</Paragraph>
              <img src={logoPlanB} alt="logo_planb" style={{ width: 100 }} />
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
