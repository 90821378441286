import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  CheckCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { Contract } from '../../../../api';
import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import UserForm from '../detail';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { CanShow } from '../../../../components';
import { ExportPdfProps } from '../../../../helpers/pdf';

const label = 'contrato';
const route = '/contracts';
const titlePdf = 'Reporte de contratos';

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Contract>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const ContractsList = () => {
  const { Text } = Typography;
  const navigate = useNavigate();
  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Contract> = (props) => {
    const { getColumnSearchProps, showModal } = props;

    return [
      {
        title: 'Proveedor',
        dataIndex: 'supplier_name',
        key: 'supplier_name',
        ...getColumnSearchProps('supplier_name'),
        render: (supplier_name) => <Text>{supplier_name}</Text>,
      },
      {
        title: 'No. contrato',
        dataIndex: 'id',
        key: 'id',
        ...getColumnSearchProps('id'),
        render: (id) => <Text>{id}</Text>,
      },
      {
        title: 'Estatus',
        key: 'status_description',
        dataIndex: 'status_description',
        filters: [
          { text: 'VIGENTE', value: 'Vigente' },
          { text: 'EN ESPERA', value: 'En espera' },
          { text: 'COMPLETADO', value: 'Completado' },
          { text: 'EXPIRADO', value: 'Expirado' },
        ],
        onFilter: (value, record) =>
          record.status_description.startsWith(value as string),
        render: (status_description) => (
          <Row gutter={16} style={{ width: 190 }}>
            <Tag
              color={
                status_description === 'Vigente'
                  ? 'yellow'
                  : status_description === 'En espera'
                  ? 'blue'
                  : status_description === 'Completado'
                  ? 'green'
                  : 'red'
              }
              key={status_description}
              style={{ marginBottom: 10 }}
            >
              {status_description.toUpperCase()}
            </Tag>
          </Row>
        ),
      },
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
        render: (name) => (
          <Text>{name.charAt(0).toUpperCase() + name.slice(1)}</Text>
        ),
      },
      {
        title: 'Descripción',
        dataIndex: 'description',
        key: 'description',
        ...getColumnSearchProps('description'),
        render: (description) => (
          <Text>
            {description.charAt(0).toUpperCase() + description.slice(1)}
          </Text>
        ),
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'created_at',
        key: 'created_at',
        // ...getColumnSearchDateProps('created_at'),
        render: (created_at) => (
          <>{moment.unix(created_at).format('DD/MM/YYYY')}</>
        ),
      },
      {
        title: 'Creado por',
        dataIndex: 'customer_name',
        key: 'customer_name',
        ...getColumnSearchProps('customer_name'),
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 170,
        align: 'center',
        render: (contract, record) => (
          <>
            {record.status < 3 && (
              <Tooltip title="Archivos">
                <Button
                  style={{
                    marginLeft: 10,
                  }}
                  ghost
                  type="primary"
                  shape="default"
                  size="small"
                  icon={<FileOutlined />}
                  onClick={() => {
                    navigate(`/contract/files/${contract.id}`);
                  }}
                />
              </Tooltip>
            )}
            {record.status < 2 && (
              <CanShow roles={['Admin', 'Financial Advisor']}>
                <>
                  <Tooltip title="Editar">
                    <Button
                      style={{
                        marginLeft: 10,
                        backgroundColor: '#1890ff',
                        borderColor: '#1890ff',
                      }}
                      type="primary"
                      shape="default"
                      size="small"
                      icon={<EditOutlined />}
                      onClick={() => showModal('edit', contract.id)}
                    />
                  </Tooltip>

                  <Tooltip title="Eliminar">
                    <Button
                      style={{
                        marginLeft: 10,
                      }}
                      danger
                      type="primary"
                      shape="default"
                      size="small"
                      icon={<DeleteOutlined />}
                      // onClick={() => handleDelete(user.id)}
                    />
                  </Tooltip>
                </>
              </CanShow>
            )}

            {record.status === 2 && (
              <Tooltip title="Completado">
                <Button
                  style={{
                    marginLeft: 10,
                    backgroundColor: '#68d78a',
                    borderColor: '#68d78a',
                  }}
                  type="primary"
                  shape="default"
                  size="small"
                  icon={<CheckCircleOutlined />}
                />
              </Tooltip>
            )}
            {record.status === 3 && (
              <Tooltip title="Expirado">
                <Button
                  danger
                  type="primary"
                  shape="default"
                  size="small"
                  icon={<ExclamationCircleOutlined />}
                />
              </Tooltip>
            )}
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Contratos',
    sheetName: 'Contratos',
    tableName: 'Tabla de contratos',
    columnNames: [
      { label: 'No. contrato', key: 'id' },
      { label: 'Proveedor', key: 'supplier_name' },
      { label: 'Nombre', key: 'name' },
      { label: 'Descripción', key: 'description' },
      { label: 'Estatus', key: 'status_description' },
      { label: 'Creado por:', key: 'customer_name' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'No. contrato', key: 'id' },
    { header: 'Proveedor', key: 'supplier_name' },
    { header: 'Nombre', key: 'name' },
    { header: 'Descripción', key: 'description' },
    { header: 'Estatus', key: 'status_description' },
    { header: 'Creado por:', key: 'customer_name' },
  ];

  const contrctTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: UserForm,
    pdfColumns,
    titlePdf,
  };
  const ContractsTable = Table<Contract>(contrctTableProps);

  return ContractsTable;
};

export default ContractsList;
