import {
  Button,
  Input,
  InputRef,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Modal as ModalAntd,
  Typography,
  notification,
} from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import {
  DeleteOutlined,
  FileOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  EditOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  ContractFile,
  ContractFileTable,
  del,
  get,
  put,
} from '../../../../api';
import Highlighter from 'react-highlight-words';
import FilesForm from '../detail';
import Modal from '../../../../components/general/modal';
import { CanShow } from '../../../../components';

const label = 'archivo';

const entries = [
  { key: 'nomina', label: 'Nómina y comprobante de pago' },
  { key: 'infonavit', label: 'Infonavit y comprobante de pago' },
  { key: 'imms', label: 'IMMS y comprobante de pago' },
  { key: 'sisub', label: 'SISUB e ICSOE (cuatrimestral)' },
  { key: 'iva', label: 'Acuse y pago IVA' },
  { key: 'additional', label: 'Archivo adicional' },
];

const FilesList = () => {
  const { Text } = Typography;
  const { confirm } = ModalAntd;

  const [innerFiles, setInnerFiles] = useState<ContractFile[]>([]);
  const [files, setFiles] = useState<ContractFileTable[]>([]);
  const [filesLoading, setFilesLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [contextModal, setContextModal] = useState<ReactElement | null>(null);
  const searchInput = useRef<InputRef>(null);
  const { id } = useParams();

  const getFilesCallback = React.useCallback(async () => {
    const http = await get<ContractFile[]>(`/contracts/${id}/files`);
    if (http.statusCode === 200) {
      setInnerFiles(() => http.response);
      let files: ContractFileTable[] = [];
      // loop entries
      entries.forEach((entry) => {
        // get file
        const file = http.response.find((file) => file.name_key === entry.key);

        // if file exists
        if (file) {
          // add file to files
          files.push({
            name: file.name,
            name_description: file.name_description,
            status:
              file.is_valid || file.name_key === 'additional'
                ? 'completo'
                : 'por_verificar',
            fileUrl: file.fileUrl,
            created_at: file.created_at,
            key: entry.key,
          });
        } else {
          // add file to files
          files.push({
            name: '-',
            name_description: entry.label,
            status: 'sin_archivo',
            fileUrl: '',
            created_at: undefined,
            key: entry.key,
          });
        }
      });
      setFiles(files);
    }
    setFilesLoading(false);
  }, [id]);

  useEffect(() => {
    if (id !== undefined) getFilesCallback();
  }, [id, getFilesCallback]);

  useEffect(() => {
    getFilesCallback();
  }, [contextModal, getFilesCallback]);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: keyof ContractFileTable
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex as string);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (
    dataIndex: keyof ContractFileTable
  ): ColumnType<ContractFileTable> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys as string[], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      (record[dataIndex] as string)
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const showModal = (
    type: string,
    row: ContractFileTable,
    file?: ContractFile
  ) => {
    setModalIsVisible(true);
    setTitleModal(
      `${
        type === 'add' ? 'Agregar' : type === 'edit' ? 'Editar' : 'Ver'
      } ${label}`
    );
    setContextModal(() => (
      <FilesForm
        row={row}
        type={type}
        file={file}
        add={(e) => {
          if (e) {
            setFiles((files) =>
              files.map((file) => {
                if (file.key === e.name_key) {
                  return {
                    ...file,
                    status:
                      e.is_valid || e.name_key === 'additional'
                        ? 'completo'
                        : 'por_verificar',
                    name: e.name,
                    fileUrl: e.fileUrl,
                    created_at: e.created_at,
                    key: e.name_key,
                  };
                }
                return file;
              })
            );
            setModalIsVisible(false);
          }
        }}
        update={(e) => {
          if (e) {
            setFiles((files) =>
              files.map((file) => {
                if (file.key === e.name_key) {
                  return {
                    ...file,
                    status:
                      e.is_valid || e.name_key === 'additional'
                        ? 'completo'
                        : 'por_verificar',
                    name: e.name,
                    fileUrl: e.fileUrl,
                    created_at: e.created_at,
                  };
                }
                return file;
              })
            );
            setModalIsVisible(false);
          }
        }}
      />
    ));
  };

  const handleDelete = (key: string) => {
    confirm({
      title: `Eliminar ${label}`,
      icon: <ExclamationCircleOutlined />,
      content: `¿Estás seguro que deseas borrar el ${label} seleccionado?, no podrá recuperarlo después.`,
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        let fileToDelete = innerFiles.find((f) => f.name_key === key);
        if (fileToDelete !== undefined) {
          del(`/files/${fileToDelete.id}`).then((res) => {
            if (res.statusCode === 200) {
              notification.success({
                message: `Se ha eliminado el ${label} correctamente.`,
              });
              // refresh files
              setFiles((files) =>
                files.map((file) => {
                  if (file.key === key) {
                    return {
                      ...file,
                      status: 'sin_archivo',
                      name: '-',
                      fileUrl: '',
                      created_at: undefined,
                    };
                  }
                  return file;
                })
              );
            }
          });
        }
      },
      onCancel() {},
    });
  };

  const handleVerification = (key: string) => {
    confirm({
      title: `Verificar ${label}`,
      icon: <ExclamationCircleOutlined />,
      content: `¿Estás seguro que deseas verificar el ${label} seleccionado?, no se podrá modificar después.`,
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        let fileToVerify = innerFiles.find((f) => f.name_key === key);
        if (fileToVerify !== undefined) {
          put(`/files/${fileToVerify.id}`, { is_valid: 1 }).then((res) => {
            if (res.statusCode === 200) {
              notification.success({
                message: `Se ha verificado el ${label} correctamente.`,
              });
              // refresh files
              setFiles((files) =>
                files.map((file) => {
                  if (file.key === key) {
                    return {
                      ...file,
                      status: 'completo',
                    };
                  }
                  return file;
                })
              );
            }
          });
        }
      },
      onCancel() {},
    });
  };

  const modalCallback = React.useCallback(
    (file: any, record: ContractFileTable) => {
      get<ContractFile[]>(`/contracts/${id}/files`).then((e) => {
        const resp = e.response;
        let algo = resp.find((f) => f.name_key === record.key);
        showModal('edit', file, algo);
      });
    },
    [id]
  );

  const columns: ColumnsType<ContractFileTable> = [
    {
      title: 'Nombre',
      dataIndex: 'name_description',
      key: 'name_description',
      ...getColumnSearchProps('name_description'),
      render: (name_description) => <Text>{name_description}</Text>,
    },
    {
      title: 'Estatus',
      key: 'status',
      dataIndex: 'status',
      filters: [
        { text: 'SIN ARCHIVO', value: 'sin_archivo' },
        { text: 'POR VERIFICAR', value: 'por_verificar' },
        { text: 'COMPLETO', value: 'completo' },
      ],
      onFilter: (value, record) => record.status.startsWith(value as string),
      render: (status: string) => (
        <Row gutter={16} style={{ width: 190 }}>
          <Tag
            color={
              status === 'completo'
                ? 'green'
                : status === 'por_verificar'
                ? 'orange'
                : 'red'
            }
            key={status}
            style={{ marginBottom: 10 }}
          >
            {status.replace('_', ' ').toUpperCase()}
          </Tag>
        </Row>
      ),
    },
    {
      title: 'Archivo',
      dataIndex: 'fileUrl',
      key: 'fileUrl',
      ...getColumnSearchProps('fileUrl'),
      render: (fileUrl, rec) => <Link to={fileUrl}>{rec.name}</Link>,
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'created_at',
      key: 'created_at',
      // ...getColumnSearchDateProps('created_at'),
      // ...getColumnSearchProps('created_at'),
      render: (created_at) => (
        <>{created_at ? moment.unix(created_at).format('DD/MM/YYYY') : '-'}</>
      ),
    },
    {
      title: 'Acciones',
      key: 'sup',
      fixed: 'right',
      width: 170,
      align: 'center',
      render: (file, record) => (
        <>
          {record.status === 'sin_archivo' && (
            <CanShow roles={['Supplier']}>
              <Tooltip title="Cargar archivo">
                <Button
                  style={{
                    marginLeft: 10,
                    backgroundColor: '#1890ff',
                    borderColor: '#1890ff',
                  }}
                  type="primary"
                  shape="default"
                  size="small"
                  icon={<FileOutlined />}
                  onClick={() => {
                    showModal('add', file);
                  }}
                />
              </Tooltip>
            </CanShow>
          )}

          {(record.status === 'por_verificar' ||
            (record.key === 'additional' && record.fileUrl)) && (
            <CanShow roles={['Supplier']}>
              <Tooltip title="Editar archivo">
                <Button
                  style={{
                    marginLeft: 10,
                  }}
                  ghost
                  type="primary"
                  shape="default"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => modalCallback(file, record)}
                />
              </Tooltip>
            </CanShow>
          )}
          {record.status === 'completo' && (
            <Tooltip title="Completo">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#68d78a',
                  borderColor: '#68d78a',
                }}
                type="primary"
                shape="default"
                size="small"
                icon={<CheckCircleOutlined />}
              />
            </Tooltip>
          )}
          {record.status === 'por_verificar' && record.key !== 'additional' && (
            <CanShow roles={['Admin', 'Financial Advisor']}>
              <Tooltip title="Verificar">
                <Button
                  style={{
                    marginLeft: 10,
                  }}
                  type="primary"
                  shape="default"
                  size="small"
                  icon={<CheckOutlined />}
                  // onclick delete file by id
                  onClick={() => handleVerification(record.key)}
                />
              </Tooltip>
            </CanShow>
          )}
          {record.name !== '-' && record.status !== 'completo' && (
            <Tooltip title="Eliminar">
              <Button
                danger
                style={{
                  marginLeft: 10,
                }}
                type="primary"
                shape="default"
                size="small"
                icon={<DeleteOutlined />}
                // onclick delete file by id
                onClick={() => handleDelete(record.key)}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return (
    <Spin spinning={filesLoading} tip={<p>Cargando...</p>}>
      {/* Tabla */}
      {!filesLoading ? (
        <Table
          columns={columns}
          dataSource={files}
          scroll={{ x: 1000 }}
          pagination={false}
        />
      ) : null}
      {/* Modal de detalle */}
      <Modal
        title={titleModal}
        isVisible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </Spin>
  );
};

export default FilesList;
