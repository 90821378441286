import { Button, Col, Layout, Row, Tooltip, Typography } from 'antd';

import './suppliers.scss';
import GreetingsTop from '../../../components/menu/greetings-top';
import SuppliersList from '../suppliers/list';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { CanShow } from '../../../components';

export default function Suppliers() {
  const { Title } = Typography;
  const navigate = useNavigate();
  return (
    <Layout>
      <GreetingsTop />
      <div className="catalog__container">
        <Row className="header-top">
          <Col xs={19} className="header-top__left">
            <Title
              level={3}
              style={{ top: 0, bottom: 0, height: '78%', margin: 'auto' }}
            >
              Proveedores
            </Title>
          </Col>
          <CanShow roles={['Admin', 'Financial Advisor']}>
            <Col xs={5}>
              <Row style={{ marginBottom: 30, justifyContent: 'right' }}>
                <Tooltip title="Atrás">
                  <Button
                    style={{ color: 'gray', fontSize: 20, height: 0, width: 0 }}
                    type="link"
                    onClick={() => navigate('/administration')}
                  >
                    <LeftOutlined />
                  </Button>
                </Tooltip>
              </Row>
            </Col>
          </CanShow>
        </Row>
      </div>
      <div className="catalog__container">
        <SuppliersList />
      </div>
    </Layout>
  );
}
