import React, { useState } from 'react';
import { Form, Button, Spin } from 'antd';
import { useNavigate } from 'react-router';

import './reset-password-form.scss';
import { ResetPasswordModel } from '../../../api/models/login/reset-password';
import { post } from '../../../api';
import Password from '../password';

type ResetPasswordFormProps = { token: string; email: string };

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: ResetPasswordModel) => {
    setLoading(true);
    values['email'] = props.email;
    values['token'] = props.token;
    let responseReset = await post('/auth/password-reset', values, true);
    if (responseReset.statusCode === 200) {
      setTimeout(() => {
        navigate('/');
      }, 3000);
    }
    setLoading(false);
  };

  return (
    <Spin tip={<p>Cargando...</p>} spinning={loading}>
      <Form onFinish={onFinish} className="login-form" layout="vertical">
        <Password type="reset" />
        <Form.Item>
          <Button
            danger
            type="primary"
            htmlType="submit"
            size="large"
            shape="round"
            className="login-form-login"
          >
            ENVIAR
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            danger
            type="primary"
            htmlType="submit"
            size="large"
            shape="round"
            className="login-form-regresar"
            onClick={() => navigate('/login')}
          >
            REGRESAR
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default ResetPasswordForm;
