import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/lib/locale/es_ES';
import 'moment/locale/es-mx';
import { UserProvider, AuthProvider, SettingsProvider } from './providers';
import { queryClient } from './config';
import router from './router';

import './App.css';

function App() {
  return (
    <div className="app">
      <ConfigProvider locale={es_ES}>
        <AuthProvider>
          <UserProvider>
            <QueryClientProvider client={queryClient}>
              <SettingsProvider>
                <RouterProvider router={router} />
              </SettingsProvider>
            </QueryClientProvider>
          </UserProvider>
        </AuthProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
