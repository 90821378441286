import { notification } from 'antd';

import { basePath } from '../config';
import ApiResponse from '../models/response';

const del = async (url: string, params?: { id: any }, notif?: boolean) => {
  let path = basePath + url;
  if (params !== undefined) {
    path += `?id=${params.id}`;
  }
  let keyToken = process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY || '';

  let fetchParams: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem(keyToken)}`,
    },
    method: 'DELETE',
  };
  let response = await fetch(path, fetchParams);
  let jsonText = await response.text();
  let respObj = JSON.parse(jsonText) as ApiResponse<unknown>;
  if (notif === true) {
    let notifBody = {
      message: respObj.message,
    };
    if (respObj.statusCode >= 200 && respObj.statusCode < 300) {
      notification['success'](notifBody);
    } else if (respObj.statusCode >= 400 && respObj.statusCode < 500) {
      notification['warning'](notifBody);
    } else if (respObj.statusCode >= 500 && respObj.statusCode < 600) {
      notification['error'](notifBody);
    }
  }
  return respObj;
};

export default del;
