import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Spin,
  Typography,
  Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { ContractFile, Supplier, get, put } from '../../../api';
import { UploadFile } from 'antd/lib';
import { RcFile } from 'antd/lib/upload';
import { basePath } from '../../../api';
import { notification } from 'antd';
import ApiResponse from '../../../api/models/response';
import { useQuery } from '@tanstack/react-query';
import { useUser } from '../../../hooks';
import { CanShow } from '../../../components';
// import moment from 'moment';
// import 'moment/locale/es';

import dayjs from 'dayjs';

import locale from 'antd/es/date-picker/locale/es_ES';

type RepseProps = {
  type: string;
  supplierId?: number;
  add: (v: any) => void;
  update: (v: any) => void;
};

let keyToken = process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY || '';

type RepseFile = ContractFile;

const RepseForm = (p: RepseProps) => {
  const { type, supplierId, add, update } = p;
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { user, refresh } = useUser();

  const { isLoading: isLoadingData, data } = useQuery<RepseFile>(
    ['repse'],
    () =>
      get<RepseFile>('/repses/getbysupplierid/' + (supplierId ?? '0')).then(
        (e) => e.response
      )
  );
  useEffect(() => {
    if (data && type !== 'add') {
      const date = dayjs.unix(data.expiration_date);
      form.setFieldsValue({
        file: {
          uid: 'tmp',
          name: data.name,
          status: 'done',
          url: data.fileUrl,
        },
        expiration_date: date,
        is_valid: data.is_valid === 1 ? true : false,
      });
      setFileList([
        { uid: 'tmp', name: data.name, status: 'done', url: data.fileUrl },
      ]);
    }
  }, [data, form]);

  const addFileFunction = async (values: any) => {
    if (type === 'add') {
      let url = basePath + '/repses';
      let supplierId = 0;
      if (user !== null && user.extendedInfo !== null) {
        supplierId = (user.extendedInfo as Supplier).id ?? 0;
      }
      const formData = new FormData();
      let file = values.file.file.originFileObj;
      formData.append('repse', file);
      formData.append('supplierId', supplierId.toString());
      let fetchParams: RequestInit = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(keyToken)}`,
        },
        method: 'POST',
        body: formData,
      };
      let httpResponse = await fetch(url, fetchParams);
      let jsonText = await httpResponse.text();
      let json = JSON.parse(jsonText) as ApiResponse<any>;
      if (json.statusCode === 201) {
        notification.success({
          message: 'Archivo REPSE subido correctamente',
        });
        // let savedFile = json.response.savedFiles[0] as ContractFile;
        add(json.response);
        refresh();
      }
    } else if (type === 'edit' && data) {
      let url = basePath + '/repses/' + data.id + '/file';
      const formData = new FormData();
      let file = values.file.file.originFileObj;
      formData.append('repse', file);
      formData.append('is_valid', '0');
      formData.append('expiration_date', values.month);
      let fetchParams: RequestInit = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(keyToken)}`,
        },
        method: 'PUT',
        body: formData,
      };
      let httpResponse = await fetch(url, fetchParams);
      let jsonText = await httpResponse.text();
      let json = JSON.parse(jsonText) as ApiResponse<any>;
      if (json.statusCode === 201) {
        notification.success({
          message: 'Archivo REPSE editado correctamente',
        });
        // let savedFile = json.response.savedFiles[0] as ContractFile;
        update(json.response);
      }
    } else if (type === 'verificar' && data) {
      const dataValues = {
        is_valid: values.is_valid,
        expiration_date: dayjs(values.expiration_date).unix(),
      };
      let res = await put<any>('/repses/' + data.id, dataValues);

      if (res.statusCode === 201) {
        notification.success({
          message: res.message,
        });
        update(res.response);
      }
    }
  };

  return (
    <Spin spinning={isLoadingData}>
      <Form name="file" form={form} onFinish={addFileFunction}>
        <Divider />
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={20}>
            <Row style={{ marginBottom: 20 }}>
              <Col xs={24} md={14} style={{ marginBottom: 20 }}>
                <Text style={{ fontSize: 18, fontWeight: 500 }}>
                  Registro de REPSE vigente:
                </Text>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="file"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione un archivo',
                    },
                  ]}
                >
                  <Upload
                    disabled={user?.role_id !== 3}
                    fileList={fileList}
                    listType="picture"
                    accept=".pdf"
                    maxCount={1}
                    style={{ width: '100% !important' }}
                    customRequest={({ file, onSuccess, onError }) => {
                      if (onSuccess) {
                        onSuccess(file);
                        let f = file as RcFile;
                        setFileList([
                          { uid: 'tmp', name: f.name, status: 'done', url: '' },
                        ]);
                      }
                    }}
                  >
                    <Button
                      disabled={user?.role_id !== 3}
                      style={{ width: 195, marginLeft: 0 }}
                      className="btn-download"
                      icon={<UploadOutlined />}
                    >
                      Subir archivo
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <CanShow roles={['Admin', 'Financial Advisor', 'Customer']}>
              <>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Fecha de expiración de archivo REPSE:"
                    name="expiration_date"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor seleccione fecha',
                      },
                    ]}
                  >
                    <DatePicker
                      locale={locale}
                      picker="date"
                      format={'DD/MM/YYYY'}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Validar REPSE:"
                    name="is_valid"
                    valuePropName="checked"
                  >
                    <Checkbox checked />
                  </Form.Item>
                </Col>
              </>
            </CanShow>

            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={type === 'watch'}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default RepseForm;
