import moment from 'moment';
import { writeFile, utils } from 'xlsx';

// const defaultExcelFilename = 'archivo_1';
// const defaultSheetName = 'hoja_1';
// const defaultTableName = 'tabla_1';

type ExportExcelProps = {
  fileName: string;
  sheetName: string;
  tableName: string;
  columnNames: { label: string; key: string }[];
};

const exportToExcel = <T>(table: T[], props: ExportExcelProps) => {
  const fileName = props.fileName; // ?? defaultExcelFilename;
  const sheetName = props.sheetName; // ?? defaultSheetName;
  const tableName = props.tableName; // ?? defaultTableName;
  const columnNames = props.columnNames; // ?? ['no_column_names'];

  const exportDate = moment().format('LLL');
  const columnKeys = columnNames.map((c) => c.key);
  const data: string[][] = table.map((row) => {
    let toArr: string[] = [];
    let objectKeys = Object.keys(row as any);
    let objectKeysInColumnKeys = objectKeys
      .filter((objKey) => columnKeys.includes(objKey))
      .sort(
        (a_objKey, b_objKey) =>
          columnKeys.indexOf(a_objKey) - columnKeys.indexOf(b_objKey)
      );
    objectKeysInColumnKeys.forEach((e) => {
      toArr.push((row as any)[e] as string);
    });
    return toArr;
  });

  const sheetData: string[][] = [
    [tableName, exportDate],
    [],
    columnNames.map((c) => c.label),
    ...data,
  ];

  const workbook = utils.book_new();
  const worksheet = utils.aoa_to_sheet(sheetData);

  workbook.SheetNames.push(sheetName);
  workbook.Sheets[sheetName] = worksheet;
  writeFile(workbook, fileName + '.xlsx', { cellStyles: true });
};

export type { ExportExcelProps };
export { exportToExcel };
