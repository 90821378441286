import { Button, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';

import { Supplier, User } from '../../../../api';
import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import SupplierForm from '../detail';
import { CanShow } from '../../../../components';
import { ExportPdfProps } from '../../../../helpers/pdf';

const label = 'proveedor';
const route = '/suppliers';
const titlePdf = 'Reporte de proveedores';

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<User>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const SupplierList = () => {
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<User> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Nombre',
        width: 150,
        dataIndex: 'username',
        key: 'username',
        ...getColumnSearchProps('username'),
        render: (username) => (
          <>
            {username && (
              <Text>
                {username.charAt(0).toUpperCase() + username.slice(1)}
              </Text>
            )}
            {!username && (
              <Text disabled italic>
                No asignado
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Empresa',
        width: 150,
        dataIndex: 'company_name',
        key: 'company_name',
        ...getColumnSearchProps('company_name'),
        render: (company_name) => (
          <Text>
            {company_name.charAt(0).toUpperCase() + company_name.slice(1)}
          </Text>
        ),
      },
      {
        title: 'Dirección',
        width: 150,
        dataIndex: 'address_1',
        key: 'address_1',
        ...getColumnSearchProps('address_1'),
        render: (address_1) => (
          <Text>{address_1.charAt(0).toUpperCase() + address_1.slice(1)}</Text>
        ),
      },
      {
        title: 'Teléfono',
        width: 150,
        dataIndex: 'phone',
        key: 'phone',
        ...getColumnSearchProps('phone'),
      },
      {
        title: 'Repse',
        width: 150,
        dataIndex: 'repse',
        key: 'repse',
        ...getColumnSearchProps('repse'),
      },
      {
        title: 'Ciudad',
        width: 150,
        dataIndex: 'city',
        key: 'city',
        ...getColumnSearchProps('city'),
        render: (city) => (
          <Text>{city.charAt(0).toUpperCase() + city.slice(1)}</Text>
        ),
      },
      {
        title: 'Estado',
        width: 150,
        dataIndex: 'state',
        key: 'state',
        ...getColumnSearchProps('state'),
        render: (state) => (
          <Text>{state.charAt(0).toUpperCase() + state.slice(1)}</Text>
        ),
      },
      {
        title: 'Correo electrónico',
        width: 200,
        dataIndex: 'email',
        key: 'email',
        ...getColumnSearchProps('email'),
        render: (email) => <Text>{email.toLowerCase()}</Text>,
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 160,
        align: 'center',
        render: (supplier) => (
          <>
            <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="default"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', supplier.id)}
              />
            </Tooltip>
            {supplier.is_valid !== null && (
              <Tooltip
                title={`${
                  supplier.is_valid === 0
                    ? 'Verificar REPSE'
                    : 'REPSE verificado'
                }`}
              >
                <Button
                  style={{
                    marginLeft: 10,
                    backgroundColor: '#68d78a',
                    borderColor: '#68d78a',
                  }}
                  type="primary"
                  shape="default"
                  size="small"
                  icon={
                    supplier.is_valid === 0 ? (
                      <FileOutlined />
                    ) : (
                      <FileDoneOutlined />
                    )
                  }
                  onClick={() => showModal('repse', supplier.id)}
                />
              </Tooltip>
            )}

            <CanShow roles={['Admin', 'Financial Advisor']}>
              <>
                <Tooltip title="Editar">
                  <Button
                    style={{
                      marginLeft: 10,
                      backgroundColor: '#1890ff',
                      borderColor: '#1890ff',
                    }}
                    type="primary"
                    shape="default"
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => showModal('edit', supplier.id)}
                  />
                </Tooltip>
                <Tooltip title="Eliminar">
                  <Button
                    style={{
                      marginLeft: 10,
                      backgroundColor: '#e63241',
                      borderColor: '#cf1322',
                    }}
                    type="primary"
                    shape="default"
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(supplier.id)}
                  />
                </Tooltip>
              </>
            </CanShow>
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Proveedores',
    sheetName: 'Proveedores',
    tableName: 'Tabla de Proveedores',
    columnNames: [
      { label: 'Nombre', key: 'username' },
      { label: 'Empresa', key: 'company_name' },
      { label: 'Dirección', key: 'address_1' },
      { label: 'Teléfono', key: 'phone' },
      { label: 'Ciudad', key: 'city' },
      { label: 'Estado', key: 'state' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'Nombre', key: 'username', width: 50 },
    { header: 'Empresa', key: 'company_name', width: 30 },
    { header: 'Dirección', key: 'address_1' },
    { header: 'Teléfono', key: 'phone' },
    { header: 'Ciudad', key: 'city' },
    { header: 'Estado', key: 'state' },
  ];

  const supplierTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: SupplierForm,
    pdfColumns,
    titlePdf,
  };
  const SuppliersTable = Table<Supplier>(supplierTableProps);

  return SuppliersTable;
};

export default SupplierList;
