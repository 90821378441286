import React, { useState, createContext } from 'react';

type SettingsContextProps = {
  menuCollapsed?: boolean;
  setMenuCollapsed?: (p: boolean) => void;
};
const defaultProps: SettingsContextProps = {};

export const SettingsContext = createContext(defaultProps);

type SettingsProviderProps = {
  children: React.ReactNode;
};
export default function SettingsProvider(props: SettingsProviderProps) {
  const { children } = props;
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  return (
    <SettingsContext.Provider value={{ menuCollapsed, setMenuCollapsed }}>
      {children}
    </SettingsContext.Provider>
  );
}
