export function validateTextHasCharacter(password: string) {
  // eslint-disable-next-line no-useless-escape
  // const re = /^(?=.*[?¡\¿\()\!@#\$%\^&\*\[\]"\';:_\-<>\., =\+\/\\])/;
  const re = /[\/\?\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
  return re.test(password);
}

export function validatePassword(password: string) {
  // eslint-disable-next-line no-useless-escape
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\/\?\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]).{6,100}$/;
  return re.test(password);
}

export function validateTextHasCapitalize(password: string) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(?=.*[A-Z])/;
  return re.test(password);
}

export function validateTextHasLowercase(password: string) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(?=.*[a-z])/;
  return re.test(password);
}

export function validateTextHasNumber(password: string) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(?=.*[0-9])/;
  return re.test(password);
}
