import { Col, Divider, Form, Input, Row, Typography } from 'antd';
import React, { useState } from 'react';
import {
  validatePassword,
  validateTextHasCapitalize,
  validateTextHasCharacter,
  validateTextHasLowercase,
  validateTextHasNumber,
} from '../../../utils/formValidation';
import {
  CloseCircleFilled,
  LockOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
const Password = ({ type }: { type: string }) => {
  const { Text } = Typography;
  const initialValue = type !== 'reset' ? true : false;
  const [hasUpperCase, setHasUpperCase] = useState(initialValue);
  const [hasLowerCase, setHasLowerCase] = useState(initialValue);
  const [hasNumber, setHasNumber] = useState(initialValue);
  const [lenghtCorrect, setLenghtCorrect] = useState(initialValue);
  const [lenghtMaxCorrect, setLenghtMaxCorrect] = useState(initialValue);
  const [hasCharacter, setHasCharacter] = useState(initialValue);

  const checkPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    if (validateTextHasCapitalize(text)) {
      setHasUpperCase(true);
    } else {
      setHasUpperCase(false);
    }

    if (validateTextHasLowercase(text)) {
      setHasLowerCase(true);
    } else {
      setHasLowerCase(false);
    }

    if (validateTextHasNumber(text)) {
      setHasNumber(true);
    } else {
      setHasNumber(false);
    }
    if (validateTextHasCharacter(text)) {
      setHasCharacter(true);
    } else {
      setHasCharacter(false);
    }
    if (text.length >= 6) {
      setLenghtCorrect(true);
    } else {
      setLenghtCorrect(false);
    }
    if (text.length <= 10) {
      setLenghtMaxCorrect(true);
    } else {
      setLenghtMaxCorrect(false);
    }
  };

  return (
    <div>
      <Form.Item
        label="Contraseña:"
        name="password"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese una contraseña',
          },
          () => ({
            validator(rule, value) {
              if (
                validatePassword(value) &&
                lenghtCorrect &&
                lenghtMaxCorrect
              ) {
                return Promise.resolve();
              } else if (!validatePassword(value)) {
                return Promise.reject();
              }
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          onChange={checkPassword}
          placeholder="Ingresa tu información"
        />
      </Form.Item>
      {type === 'reset' && (
        <div className="validate-password">
          <Row>
            <Col xs={24}>
              <Divider>La contraseña debe incluir:</Divider>
              <Row>
                {hasUpperCase && (
                  <Text type="success">
                    <CheckCircleFilled style={{ marginRight: 8 }} />
                    Una mayúscula{' '}
                  </Text>
                )}
              </Row>
              <Row>
                {!hasUpperCase && (
                  <Text type="danger">
                    <CloseCircleFilled style={{ marginRight: 8 }} />
                    Una mayúscula{' '}
                  </Text>
                )}
              </Row>
              <Row>
                {hasLowerCase && (
                  <Text type="success">
                    <CheckCircleFilled style={{ marginRight: 8 }} />
                    Una minúscula{' '}
                  </Text>
                )}
              </Row>
              <Row>
                {!hasLowerCase && (
                  <Text type="danger">
                    <CloseCircleFilled style={{ marginRight: 8 }} />
                    Una minúscula{' '}
                  </Text>
                )}
              </Row>
              <Row>
                {hasNumber && (
                  <Text type="success">
                    <CheckCircleFilled style={{ marginRight: 8 }} />
                    Un número{' '}
                  </Text>
                )}
              </Row>
              <Row>
                {!hasNumber && (
                  <Text type="danger">
                    <CloseCircleFilled style={{ marginRight: 8 }} />
                    Un número{' '}
                  </Text>
                )}
              </Row>
              <Row>
                {hasCharacter && (
                  <Text type="success">
                    <CheckCircleFilled style={{ marginRight: 8 }} />
                    Un carácter: {'?()!@#$%^&*[]"\';:_-<>. =+/ '}{' '}
                  </Text>
                )}
              </Row>
              <Row>
                {!hasCharacter && (
                  <Text type="danger">
                    <CloseCircleFilled style={{ marginRight: 8 }} />
                    Un carácter: {'?()!@#$%^&*[]"\';:_-<>. =+/'}
                  </Text>
                )}
              </Row>
              <Row>
                {lenghtCorrect && (
                  <Text type="success">
                    <CheckCircleFilled style={{ marginRight: 8 }} />
                    Tiene 6 o más caracteres{' '}
                  </Text>
                )}
              </Row>
              <Row>
                {!lenghtCorrect && (
                  <Text type="danger">
                    <CloseCircleFilled style={{ marginRight: 8 }} />
                    Tiene 6 o más caracteres{' '}
                  </Text>
                )}
              </Row>

              <Row>
                {!lenghtMaxCorrect && (
                  <Text type="danger">
                    <CloseCircleFilled style={{ marginRight: 8 }} />
                    Máximo 10 caracteres{' '}
                  </Text>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      )}
      {type !== 'reset' && (
        <>
          {!hasUpperCase && (
            <Text type="danger">
              <CloseCircleFilled style={{ marginRight: 8 }} />
              Una mayúscula{' '}
            </Text>
          )}
          <Row>
            {!hasLowerCase && (
              <Text type="danger">
                <CloseCircleFilled style={{ marginRight: 8 }} />
                Una minúscula{' '}
              </Text>
            )}
          </Row>
          <Row>
            {!hasNumber && (
              <Text type="danger">
                <CloseCircleFilled style={{ marginRight: 8 }} />
                Un número{' '}
              </Text>
            )}
          </Row>
          {!hasCharacter && (
            <Col>
              <Text type="danger">
                <CloseCircleFilled style={{ marginRight: 8 }} />
                Un carácter: {'?()!@#$%^&*[]"\';:_-<>. =+/'}
              </Text>
            </Col>
          )}
          {!lenghtCorrect && (
            <Col>
              <Text type="danger">
                <CloseCircleFilled
                  style={{
                    marginRight: 8,
                    marginBottom: 10,
                  }}
                />
                Tiene 6 o más caracteres{' '}
              </Text>
            </Col>
          )}
          {!lenghtMaxCorrect && (
            <Col>
              <Text type="danger">
                <CloseCircleFilled style={{ marginRight: 8 }} />
                Máximo 10 caracteres{' '}
              </Text>
            </Col>
          )}
        </>
      )}

      <Form.Item
        label="Confirmar contraseña:"
        name="passwordConfirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Por favor confirme la contraseña',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                'Las dos contraseñas ingresadas no son iguales'
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Ingresa tu información"
        />
      </Form.Item>
    </div>
  );
};

export default Password;
