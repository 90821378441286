import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { User } from '../../../../api';
import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import UserForm from '../detail';
import { ExportPdfProps } from '../../../../helpers/pdf';

const label = 'usuario';
const route = '/users';
const titlePdf = 'Reporte de usuarios';

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<User>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const UsersList = () => {
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<User> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Nombre',
        width: 100,
        ellipsis: true,
        dataIndex: 'username',
        key: 'username',
        ...getColumnSearchProps('username'),
        render: (username) => (
          <>
            {username && (
              <Text>
                {username.charAt(0).toUpperCase() + username.slice(1)}
              </Text>
            )}
            {!username && (
              <Text disabled italic>
                No asignado
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Rol',
        width: 60,
        dataIndex: 'role_description',
        key: 'role_description',
        filters: [
          { text: 'Admin', value: 'Admin' },
          { text: 'Financiero', value: 'Financial Advisor' },
          { text: 'Proveedor', value: 'Supplier' },
          { text: 'Cliente', value: 'Customer' },
        ],
        onFilter: (value, record) =>
          record.role_description.startsWith(value as string),
        // onFilter: (value: string, record) => record.role.includes(value),
        render: (role) => (
          <Row gutter={16} style={{ width: 190 }}>
            <Tag
              color={
                role === 'Admin'
                  ? 'red'
                  : role === 'Financial Advisor'
                  ? 'blue'
                  : role === 'Supplier'
                  ? 'green'
                  : 'yellow'
              }
              key={role}
              style={{ marginBottom: 10 }}
            >
              {role === 'Admin'
                ? 'Administrador'
                : role === 'Financial Advisor'
                ? 'Financiero'
                : role === 'Supplier'
                ? 'Proveedor'
                : 'Cliente'}
            </Tag>
          </Row>
        ),
      },
      {
        title: 'Correo electrónico',
        width: 100,
        dataIndex: 'email',
        key: 'email',
        ...getColumnSearchProps('email'),
        render: (email) => <Text>{email.toLowerCase()}</Text>,
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 60,
        align: 'center',
        render: (user) => (
          <>
            <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="default"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', user.id)}
              />
            </Tooltip>
            <Tooltip title="Editar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#1890ff',
                  borderColor: '#1890ff',
                }}
                type="primary"
                shape="default"
                size="small"
                icon={<EditOutlined />}
                onClick={() => showModal('edit', user.id)}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#e63241',
                  borderColor: '#cf1322',
                }}
                type="primary"
                shape="default"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(user.id)}
              />
            </Tooltip>
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Usuarios',
    sheetName: 'Usuarios',
    tableName: 'Tabla de Usuarios',
    columnNames: [
      { label: 'Nombre', key: 'username' },
      { label: 'Correo Electrónico', key: 'email' },
      { label: 'Rol', key: 'role_description' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'Nombre', key: 'username', width: 50 },
    { header: 'Correo Electrónico', key: 'email', width: 30 },
    { header: 'Rol', key: 'role_description', width: 30 },
  ];
  const userTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: UserForm,
    pdfColumns,
    titlePdf,
  };
  const UsersTable = Table<User>(userTableProps);

  return UsersTable;
};

export default UsersList;
