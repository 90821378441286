import { Layout, Row } from 'antd';
import GreetingsTop from '../../../components/menu/greetings-top';
import './unauthorized.scss';

const UnAuthorized = () => {
  return (
    <Layout className="admin">
      <GreetingsTop />
      <Row justify={'center'}>
        <h1 style={{ fontSize: '30px' }}>Página no autorizada</h1>
      </Row>
    </Layout>
  );
};

export default UnAuthorized;
