import React from 'react';
import { Modal as ModalAntd } from 'antd';

type ModalProps = {
  children: React.ReactNode;
  title: string;
  isVisible: boolean;
  setIsVisible: (val: boolean) => void;
  isCentered?: boolean;
  width?: number;
};

const Modal = (props: ModalProps) => {
  const { children, title, isVisible, setIsVisible, isCentered, ...other } =
    props;

  return (
    <ModalAntd
      title={title}
      centered={isCentered}
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      footer={false}
      destroyOnClose
      {...other}
    >
      {children}
    </ModalAntd>
  );
};

export default Modal;
