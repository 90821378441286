import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu, Avatar, Image } from 'antd';
import {
  PoweroffOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DownOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import apLogo from '../../../assets/img/png/ap-logo.png';
import apLogoBlue from '../../../assets/img/png/ap-logo-blue.png';
import useUser from '../../../hooks/useUser';

import './menu-top.scss';
import { useAuth } from '../../../hooks';

const MenuTop = (props: { menuCollapsed: any; setMenuCollapsed: any }) => {
  const { menuCollapsed, setMenuCollapsed } = props;
  // const { person } = useUser();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [profilePicture, setProfilePicture] = useState(null);
  // useEffect(() => {
  //   if (person) {
  //     const data = person.profilePicture;
  //     if (data !== '') {
  //       const imageProfile = `data:image/jpeg;base64, ${data}`;
  //       // setProfilePicture(imageProfile);
  //     }
  //   } else {
  //     logOutError();
  //   }
  // }, [person]);

  const menu = (
    <Menu>
      <Menu.Item key="pictureUser">
        <Image
          src={profilePicture ? profilePicture : apLogoBlue}
          style={{ width: 100 }}
        />
      </Menu.Item>
      <Menu.Item key="/profile">
        <Link to="/profile">
          <UserOutlined />
          <span className="nav-text">Perfil</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        key="nameUser"
        danger
        icon={<PoweroffOutlined />}
        onClick={() => logoutUser()}
      >
        Salir
      </Menu.Item>
    </Menu>
  );
  const logoutUser = () => {
    logout();
    navigate('/login');
    // window.location.reload();
  };
  return (
    <div className="menu-top">
      <div className="menu-top__left">
        <img src={apLogo} alt="apLogo" style={{ width: 140, marginLeft: 20 }} />
        <Button
          style={{ marginTop: 10 }}
          icon={menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          type="link"
          size="large"
          onClick={() => setMenuCollapsed(!menuCollapsed)}
        ></Button>
      </div>

      <div className="menu-top__right">
        <Dropdown overlay={menu}>
          <a href="javascript: void(0)" className="ant-dropdown-link">
            <Avatar
              shape="circle"
              src={profilePicture ? profilePicture : apLogoBlue}
              style={{ marginRight: 5 }}
            />{' '}
            {/* {person?.name} */}
            <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default MenuTop;
